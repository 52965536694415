<template>
	<div class="hide-scrollbar"
		style="overflow-y: scroll; width: 100%; background-color: #f7f5f9; height: 100%; padding: 0 25px 25px;">
		<div class="dF aC jSB mb-3">
			<h3>Invite Links</h3>
			<div class="dF aC" style="gap: 20px">
				<a-input placeholder="Search invite here..." v-model="search" style="width: 300px" allow-clear>
					<a-icon slot="prefix" type="search" />
				</a-input>
				<a-button type="primary" @click="openInviteLinkModal()">Create New Invite Link</a-button>
			</div>
		</div>
		<div>
			<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="inviteLinks" :loading="loading" :pagination="{
				current: currentPage,
				total: totalInviteLinks,
				pageSize: pageSize,
			}" @change="handleChange" class="white-table" :scroll="{ x: 1100 }">
				<template slot="name" slot-scope="link">
					{{ link.name }}
				</template>
				<template slot="role" slot-scope="link">
					<template v-if="link.role?.name === 'bh_admin'"> Admin </template>
					<template v-else-if="link.role?.name === 'bh_agent'"> VIP Realtor </template>
					<template v-else-if="link.role?.name === 'bh_agent_manager'"> VIP Broker Manager </template>
					<template v-else> {{ link.role?.name }} </template>
				</template>
				<template slot="token" slot-scope="link">
					{{ link.token }}
				</template>
				<template slot="expiresAt" slot-scope="link">
					{{ $formatDate(link.expiresAt) }}
				</template>
				<template slot="active" slot-scope="link">
					<span v-if="link.expiresAt && link.expiresAt < Date.now()" class="button"
						style="background-color: var(--danger)">EXPIRED</span>
					<span v-else-if="link.active" class="button" style="background-color: var(--success)">ACTIVE</span>
					<span v-else class="button" style="background-color: var(--danger)"> INACTIVE </span>
				</template>
				<template slot="joinedUsers" slot-scope="link">
					<span v-if="link.joined_users?.length">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template #title>
								<div v-for="user in link.joined_users" :key="user.id" class="dF aC"
									style="gap: 5px; margin-bottom: 5px">
									{{ user.firstName }} {{ user.lastName }} <br />
								</div>
							</template>
							<a-avatar style="margin-left: -10px" shape="circle" class="cursor-pointer" size="default"
								v-for="user in first3Users(link.joined_users)" :key="user.id" :style="{
									'background-color':
										user.id === 'id'
											? '#9693e7'
											: 'var(--primary)',
								}">
								{{ user.id !== "id" ? user.firstName?.[0]?.toUpperCase() : user.firstName }}
							</a-avatar>
						</a-tooltip>
					</span>
				</template>
				<template slot="createdBy" slot-scope="link">
					<a-tooltip overlayClassName="change-tooltip-color" :title="link.createdBy?.email">
						{{ link.createdBy?.firstName }} {{ link.createdBy?.lastName }}
					</a-tooltip>
				</template>
				<template slot="createdAt" slot-scope="link">
					{{ $formatDate(link.createdAt) }}
				</template>
				<template slot="actions" slot-scope="link">
					<div class="dF aC text-md" style="gap: 20px">
						<a-tooltip overlayClassName="change-tooltip-color" title="Copy Invite Link">
							<a-icon type="link" @click="onCopyClick(link)" />
						</a-tooltip>
						<a-tooltip v-if="!isUserBrokerManager || (link.createdBy?.id === user.id)"
							overlayClassName="change-tooltip-color" title="">
							<a-icon type="edit" @click="openInviteLinkModal(link)" />
						</a-tooltip>
						<a-tooltip v-if="!isUserBrokerManager || (link.createdBy?.id === user.id)"
							overlayClassName="change-tooltip-color" title="">
							<a-icon type="delete" @click="deleteInviteLink(link)" />
						</a-tooltip>
					</div>
				</template>
			</a-table>
		</div>

		<a-modal v-model="inviteLinkModal.visible" :title="inviteLinkModal.title" :confirmLoading="loading"
			@ok="onSubmit" :okText="inviteLinkModal.okText" cancelText="CANCEL">
			<a-form-model ref="inviteLink" :model="inviteLink">
				<a-form-model-item label="Name" prop="name" :rules="$req('Please enter name')">
					<a-input v-model="inviteLink.name" size="large" />
				</a-form-model-item>
				<a-form-model-item label="Role" prop="role"
					:rules="$req('Please select user role for this invite link')">
					<a-select v-model="inviteLink.role" style="width: 100%" size="large" :disabled="!!inviteLink.id">
						<a-select-option v-for="role in roles" :key="role.id" :value="role.id">
							{{ role.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item prop="expiresAt">
					<template #label>
						<a-tooltip overlayClassName="change-tooltip-color">
							<template #title>
								Invite link will be expired after this date. After expiration, the user will not be able
								to register using this link.
								<br />
								Empty expiresAt means the link will never expire.
							</template>
							<span>Expires At</span>
							<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 5px;" />
						</a-tooltip>
					</template>
					<a-date-picker v-model="inviteLink.expiresAt" style="width: 100%" size="large" :disabled-date="disabledDate" />
				</a-form-model-item>
				<a-form-model-item prop="active" :rules="$req('Please select status for this invite link')">
					<template #label>
						<a-tooltip overlayClassName="change-tooltip-color">
							<template #title>
								When turned on, the link is active, allowing users with the link to join the project as
								team members. When turned off, the link becomes inactive, and invitees will no longer be
								able to join the team or project.
							</template>
							<span>Status</span>
							<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 5px;" />
						</a-tooltip>
					</template>
					<a-switch v-model="inviteLink.active" size="large" />
				</a-form-model-item>
				<a-form-model-item v-if="inviteLink.token" label="Invite Link">
					<a-input :value="`https://admin.bildhive.${$tld}?invite=${inviteLink.token}`" size="large" disabled>
						<div slot="addonBefore" class="dF aC" style="gap: 5px">
							<a-icon type="copy" @click="onCopyClick(inviteLink.token)" />
						</div>
					</a-input>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
	data() {
		return {
			loading: false,
			inviteLinkModal: {
				visible: false,
				title: 'Create New User Invite Link',
				okText: 'CREATE',
				isEditing: false,
			},
			search: '',
			currentPage: 1,
			pageSize: 10,
			totalInviteLinks: 0,
			sortedInfo: {
				key: "updatedAt",
				order: "DESC",
			},
			inviteLink: {
				name: '',
				role: null,
				expiresAt: null,
				active: true,
			},
			inviteLinks: [],
			columns: [
				{
					title: 'Name',
					key: 'name',
					scopedSlots: { customRender: 'name' },
					sorter: true,
				},
				{
					title: 'Role',
					key: 'role',
					scopedSlots: { customRender: 'role' },
					sorter: true,
				},
				{
					title: 'Expires Date',
					key: 'expiresAt',
					scopedSlots: { customRender: 'expiresAt' },
					sorter: true,
				},
				{
					title: 'Status',
					key: 'active',
					scopedSlots: { customRender: 'active' },
					sorter: true,
				},
				{
					title: 'Joined Users',
					key: 'joinedUsers',
					scopedSlots: { customRender: 'joinedUsers' },
				},
				{
					title: 'Created By',
					key: 'createdBy',
					scopedSlots: { customRender: 'createdBy' },
					sorter: true,
				},
				{
					title: 'Created Date',
					key: 'createdAt',
					scopedSlots: { customRender: 'createdAt' },
					sorter: true,
				},
				{
					title: 'Actions',
					key: 'actions',
					scopedSlots: { customRender: 'actions' },
				},
			],
		}
	},

	computed: {
		instance() {
			return this.$store.state.instance
		},

		user() {
			return this.$store.state.user.user || {};
		},

		roles() {
			const defaultRoles = {
				'bh_admin': 'Admin',
				'bh_agent': 'VIP Realtor',
				'bh_agent_manager': 'VIP Broker Manager',
			}
			const roles = this.$store.state.roles || [];
			const list = roles.map(r => {
				return {
					...r,
					name: defaultRoles[r.name] || r.name,
				}
			})

			// sort list by name. default roles should be at the top
			list.sort((a, b) => {
				if (a.name === 'Admin') return -1;
				if (b.name === 'Admin') return 1;
				if (a.name === 'VIP Realtor') return -1;
				if (b.name === 'VIP Realtor') return 1;
				if (a.name === 'VIP Broker Manager') return -1;
				if (b.name === 'VIP Broker Manager') return 1;
				return a.name.localeCompare(b.name);
			});

			return list;
		},

		isUserBrokerManager() {
			const brokerManagerRole = this.$store.getters.brokerManagerRole;
			const currentUser = this.instance?.users?.find(u => u.user?.id === this.user.id);
			return currentUser?.role?.id === brokerManagerRole?.id;
		}
	},

	watch: {
		search() {
			this.searchDebounce();
		},
	},

	created() {
		this.$store.commit("UPDATE_SELECTED_ROLE", 'inviteLinks');
		this.fetchInviteLinksDetails()
	},

	methods: {
		searchDebounce: _.debounce(function () {
			this.fetchInviteLinksDetails()
		}, 1000),

		fetchInviteLinksDetails() {
			this.currentPage = 1
			this.getInviteLinks();
			this.getTotalInviteLinksCount();
		},

		async getTotalInviteLinksCount() {
			try {
				let searchQuery = "";
				if (this.search) {
					searchQuery += `?_q=${this.search}`;
				}
				const { data } = await this.$api.get(`user-invite-links/:instance/count${searchQuery}`);
				this.totalInviteLinks = data;
			} catch (err) {
				this.$toastError(err, 'Error while fetching count. Please try again')
			}
		},

		async getInviteLinks() {
			this.loading = true;
			try {
				let start = (this.currentPage - 1) * this.pageSize;
				let searchQuery = "";
				if (this.search) {
					searchQuery = `&_q=${this.search}`;
				}
				const { data } = await this.$api.get(
					`/user-invite-links/:instance?${searchQuery}&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}`
				);
				this.inviteLinks = data;
			} catch (error) {
				this.$toastError(err, 'Error while fetching invite link list. Please try again')
			}
			this.loading = false;
		},

		openInviteLinkModal(link = null) {
			this.inviteLink = {
				id: link?.id ?? null,
				name: link?.name ?? null,
				role: link?.role?.id ?? null,
				expiresAt: link?.expiresAt ? moment(link.expiresAt) : null,
				active: link?.active ?? true,
				token: link?.token ?? null,
			}

			this.inviteLinkModal = {
				visible: true,
				title: link ? 'Edit User Invite Link' : 'Create New User Invite Link',
				okText: link ? 'UPDATE' : 'CREATE',
				isEditing: !!link,
			};

			this.$nextTick(() => {
				this.$refs.inviteLink.clearValidate();
			});
		},

		onSubmit() {
			this.$refs.inviteLink.validate(async (valid) => {
				if (!valid) {
					return;
				}

				const payload = { ...this.inviteLink }

				if (payload.expiresAt) {
					payload.expiresAt = +moment(payload.expiresAt).format('x');
				}

				let url = '/user-invite-links/:instance';
				let method = 'post';
				let successMessage = 'New user invite link created successfully';
				let errorMessage = 'Error while creating a new user invite link. Please try again';

				if (this.inviteLinkModal.isEditing) {
					url = `/user-invite-links/:instance/${this.inviteLink.id}`;
					method = 'put';
					successMessage = 'User invite link updated successfully';
					errorMessage = 'Error while updating a user invite link. Please try again';
				}

				this.loading = true;
				try {
					await this.$api[method](url, payload);

					this.$message.success(successMessage);
					this.inviteLinkModal = {
						visible: false,
						title: 'Create New User Invite Link',
						okText: 'CREATE',
						isEditing: false,
					};
					this.fetchInviteLinksDetails();
				} catch (err) {
					this.$toastError(err, errorMessage)
				}
			});
		},

		deleteInviteLink(link) {
			if (this.$p < 40) {
				this.$message.error('You do not have permission to delete invite links');
			}

			this.$confirm({
				title: (h) => <div>Are you sure you want to delete this invite link <strong>{link.name}</strong>?</div>,
				icon: 'exclamation-circle',
				okType: 'danger',
				okText: 'DELETE',
				cancelText: 'CANCEL',
				centered: true,
				confirmLoading: this.loading,
				onOk: async () => {
					this.loading = true;
					try {
						await this.$api.delete(`/user-invite-links/:instance/${link.id}`);
						this.$message.success('Invite link deleted successfully');
						this.loading = false;
						this.fetchInviteLinksDetails();
					} catch (err) {
						this.loading = false;
						this.$toastError(err, 'Error while deleting invite link. Please try again')
					}

				},
			});
		},

		onCopyClick(link) {
			const url = `https://admin.bildhive.${this.$tld}?invite=${link.token}`;

			window.navigator.clipboard.writeText(url).then(() => {
				this.$message.success('Invite link copied to clipboard');
			}).catch((err) => {
				this.$toastError(err, 'Error while copying invite link to clipboard')
			});
		},

		first3Users(users) {
			const extraMembers = users.length > 3 ? users.length - 3 : 0;
			users = users.filter((u, i) => i < 3);
			if (extraMembers) {
				users.push({ firstName: `+${extraMembers}`, id: "id" });
			}
			return users;
		},

		handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
			}
			if (sorter && sorter.column) {
				this.sortedInfo = {
					key: sorter.columnKey,
					order: sorter.order === "descend" ? "DESC" : "ASC",
				};
			}
			this.getInviteLinks();
		},

		disabledDate(current) {
			return current && current < moment().endOf("day");
		},
	}
}
</script>

<style lang="scss" scoped>
.button {
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	text-align: center;
	padding: 4px 10px;
}
</style>
